import { useState } from "react";
import "./App.css";

function getJSON(url_: string) {
    return new Promise<any>((resolve_, reject_) => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4) {
                if (httpRequest.status === 200 || httpRequest.status === 204) {
                    try {
                        resolve_(httpRequest.responseText);
                    }
                    catch {
                        reject_();
                    }
                }
                else {
                    reject_();
                }
            }
        };
        httpRequest.open('GET', url_, true);
        httpRequest.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        httpRequest.send();
    });
}

function App() {
    const [legend, setLegend] = useState(true);
    const empty: {roadmap: any[], legend: any[], title: string} = {roadmap: [], legend: [], title: ""};
    const [data, setData] = useState(empty);

    if (data.roadmap.length === 0) {
        getJSON("/json/data.js").then((data_) => {
            const f = new Function(data_);
            const data = f.apply(undefined);
            setData(data);
        });
    }

    function renderModules(modules_: any[]) {
        var result: JSX.Element[] = [];
        modules_.forEach((module_, index_) => {
            result.push(<div key={"module_" + index_} className={"module " + module_.color} style={{ flex: module_.weight ?? 1 }}><span>{module_.name}</span></div>);
        })
        return result;
    }
    
    function renderProduct(product_: any, key_: string) {
        return <div className="product" key={key_}>
            <div className="productName"><span>{product_.name}</span></div>
            <div className="productDescription"><span>{product_.description}</span></div>
            <div className="productInsteadOf"><span>{product_.insteadof}</span></div>
            {renderModules(product_.modules ?? [])}
        </div>;
    }
    
    function renderProducts() {
        var result: JSX.Element[] = [];
        data.roadmap.forEach((product_: any, index_) => {
            if (product_.visible !== false)
                result.push(renderProduct(product_, "product_" + index_));
        })
        return result;
    }

    function renderLegend() {
        var result: JSX.Element[] = [];
        data.legend.forEach((item_: any, index_) => {
            result.push(<div key={"legend_"+index_} className={"module " + item_.color}><div className="legendItem">{item_.name}</div></div>);
        })
        return result;
    }
    
    return <div className="scroll">
        <div className="caption">
            <span>{data.title}</span>
        </div>
        <div className="products">
            {renderProducts()}
        </div>
        {legend ?
            <div className="legend">
                <div className="legendCaption"><div className="legendItem">Легенда</div>
                    <div className="legendClose" onClick={() => { setLegend(false); }}>X</div>
                </div>
                { renderLegend() }
            </div>
            : <></>
        }
    </div>;
}

export default App;